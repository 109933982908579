.login-form{
  width: 50%;
  margin: 0 auto;
  border: 1px solid #e7f0fe;
  padding: 50px;
}

.custom-form-group{
  margin-bottom: 15px;
}
