.loader {
    width: 100px;
    height: 100px;
    animation: spin infinite 1.2s linear;
    -webkit-animation: spin infinite 1.2s linear;
}

#dt-spinner{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.loader-div{
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: gray;
    width: 100%;
    height: 100%;
    opacity: 0.7;
}

.loader-div > #dt-spinner{
    height: 100%;
    margin: 0;
}
