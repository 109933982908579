.add-website{
  margin-bottom: 10px;
  text-align: left;
}

.configure-elements-list{
  max-width: 500px;
}

.add-website-form-div .form-group{
  margin-top: 20px;
}

.error-message{
  color: red;
  padding: 5px;
}

.website-button-container{
  display: flex;
}

.website-button-container button{
  height: 30px;
}

.back-to-website-list{
  text-align: left;
}
