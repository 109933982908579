.timeline{
  width: 100%;
  height: 100%;
  background: #ECF1F524;
  mix-blend-mode: normal;
  backdrop-filter: blur(15px);
  box-shadow: 0px 20px 53px -30px rgba(95, 102, 173, 0.566816);
  border-radius: 10px;



  h3{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: grey;
  }

  .card h3{
    margin: 0;
  }

  label{

    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    margin-left:66px;
    margin-top:10px;
    color: gray;

  }

  .box{
    width:100%;
    background:#fbfcfd;

    .container{
      width:100%;
      .cards{
        .card{
          width:100%;
          background: #FFFFFF;
          box-shadow:0 2px 2px 0  #eeeeee40;
          border-radius: 10px;
          box-shadow: 0px 16px 15px -10px rgba(105, 96, 215, 0.0944602);
          margin-bottom:10px;
          &.mid{
            height:71px;
          }

          h4{
            font-family: Open Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            color: #2B2862;
            margin-bottom: 0;
          }

          p{
            font-family: Open Sans;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            color: #2B2862;
            margin-bottom: 0;
            padding: 10px;
          }
        }
      }

    }
  }
  .link-redirected{
    background-color: beige;
  }

  .link-added{
    background-color: #2ecc71;
  }

  .link-removed{
    background-color: #ecb5b5;
  }

  .link-error{
    background-color: #ecb5b5;
  }

  .card-header{
    display: grid;
    grid-template-columns: 1fr 0.8fr;
    align-items: center;
    justify-items: right;
  }

  .element-added-td{
    background-color: #e6ffed;
  }

  .element-removed-td{
    background-color: #fdb8c0;
  }

  .element-modified-td{
    background-color: beige;
  }

  td{
    vertical-align: middle;
  }

  .multi-attribute-element{
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  .multi-attribute-element > span{
    font-weight: 600;
    color: grey;
  }

}
.activities-controller{
  .graph-view-options{
    border: 1px solid #e6e6e6;
    align-items: center;
  }
}
