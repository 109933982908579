.content{
  margin-top: 100px;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone{
  max-height: fit-content !important;
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeaderTitle {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  text-align: left;
}

.MuiDataGrid-cell, .MuiDataGrid-columnHeaderTitle {
  max-height: fit-content !important;
  overflow: auto;
  white-space: initial !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.MuiDataGrid-columnHeaderTitle{
  overflow-wrap: anywhere;
}

::placeholder {
  text-align: center;
}

/* or, for legacy browsers */

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder { /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}
