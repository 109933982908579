.bulk-search-console{
  background-color: whitesmoke;
  padding: 10px;
  margin: 10px 0 35px 0;

  .bulk-search-console-grid{
    display: grid;
    grid-template-columns: 2fr 5fr 1fr;
    align-items: center;
    grid-column-gap: 20px;
  }

  .text-left{
    text-align: left !important;
  }

}
