.header{
    height: 100px;
    background-color: #61dafb;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-link{
    font-size: 15px;
}

.ai-article-dropdown {
    .dropdown-menu{
        background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;
    }

    .dropdown-item:hover {
        background-color: rgba(var(--bs-dark-rgb),var(--bs-bg-opacity))!important;;
    }
}
