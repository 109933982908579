.scrollable-article {
  position: relative;

  .scrollable-article-content{
    max-height: 500px;
    overflow: scroll;
    padding: 10px;
  }

  .copy-icon {
    position: absolute;
    top: 0; /* Adjust top position as needed */
    right: 0; /* Adjust right position as needed */
    cursor: pointer;
  }
}
