.heatmap{
  background-color: white;
  padding: 20px;
  border: 2px solid #7f858b;
}

.heatmap-cell{
  color: limegreen;
  font-size: 14px;
  height: 35px;
  display: table;
  width: 100%;
}

.heatmap-cell div{
  display: table-cell;
  vertical-align: middle;
}

.y-labels{
  cursor: pointer;
  margin-top: 10px;
}

.cluster-group-table caption{
  caption-side: top;
  text-transform: uppercase;
}

.cluster-group-table td{
  vertical-align: middle;
}

.cluster-group-table td > li{
  list-style: decimal-leading-zero;
}

.close-action{
  display: flex;
  justify-content: end;
  font-size: 30px;
  position: absolute;
  right: 1%;
  top: 2%;
  cursor: pointer;
}
