.back-to-site{
    text-align: left;
}

.site-metrics > div{
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    column-gap: 10px;
    text-align: left;
}
