.individual-site{
    .wrap-text{
        overflow-wrap: anywhere;
    }

    .icon-class{
        cursor: pointer;
    }

    .back-to-page{
        margin: 10px 0;
        text-align: left;
    }

    .hide{
        visibility: hidden;
    }

    .filter{
        margin: 20px 0;
    }

    .filter > div{
        padding-left: 0
    }
}

.metrics{
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    margin-bottom: 20px;
    column-gap: 5px;
}

.metrics > div{
    border-radius: 5px;
    color: white;
    padding: 10px;
}

.clicks{
    background-color: #4285f4
}

.impressions{
    background-color: #5e35b1;
}

.ctr{
    background-color: #00897b;
}

.positions{
    background-color: #e8710a;
}

.records-count{
    background-color: #9d2be2;
}

.latest-record-date{
    background-color: #2c3e50;
}

.es-filter{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
}

.bulk-search-collapse{
    margin: 20px 0;
}

.Collapsible__trigger{
    font-size: 18px;
    margin-bottom: 10px;
    background-color: #1776d2;
    color: white;
    display: block;
    height: 40px;
    line-height: 32px;
    text-decoration: none;
    width: 120px;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
}

.Collapsible{
    border: 2px solid whitesmoke;
    padding: 10px;
}
