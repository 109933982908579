.input-container{
    display: flex;
}

.input-container > div{
    flex: 40%;
}

.separator{
    text-align: center;
    flex: 20% !important;
}

.submit-button{
    margin-top: 50%;
}

.input-error{
    color: red;
    font-size: 14px;
}

.result{
    margin-top: 5%;
}
