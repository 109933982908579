.add-user{
  margin-bottom: 10px;
  text-align: left;
}

.add-user-form-div .form-group{
  margin-top: 20px;
}

.edit-page{
  display: inline-block;
}

.add-user-form-div{
  max-height: 700px;
  overflow: auto;
}